


























import moment from 'moment'

type AccumulatorChartRecord = Pick<InviteLinkStatRecord, 'time' | 'first_leave' | 'first_enter'>

import { InviteLinkStatRecord, InviteLinksWithStat } from '@/includes/logic/InviteLinks/types'
import ChartExportTableMixin from '@/mixins/statistics/ChartExportTableMixin'

import TableExportButton from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import { ApexChartLocales, ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'

import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'

@Component({
  components: {
    TableExportButton,
    VueApexCharts
  }
})
export default class AccumulatorChart extends Mixins(ChartExportTableMixin) {
  @Prop() links!: InviteLinksWithStat

  @Prop() from!: string

  @Prop() to!: string

  @Watch('links')
  onchange() {
    this.calcChartSeries()
  }

  chartLoading = false

  series: ApexChartSeries | null = null

  options: ApexOptions | null = null

  get ticks() {
    return fromToTicks(this.from, this.to, {
      format: 'YYYY-MM-DD'
    })
  }

  calcChartSeries() {
    this.chartLoading = true

    const accStats = this.links
      .map(l => l.items)
      .flat()
      .reduce((acc: Array<AccumulatorChartRecord>, value) => {
        if (value) {
          const valueTime = moment(value.time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
          const record = acc.find(s => moment(s.time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') === valueTime)

          if (record) {
            record.first_enter += value.first_enter
            record.first_leave += value.first_leave
          } else {
            acc.push({
              time: valueTime,
              first_enter: value.first_enter,
              first_leave: value.first_leave,
            })
          }
        }
        return acc
      }, [])

    const { calcSeries } = seriesByMetricInList(this.$i18n, accStats, [ 'first_enter', 'first_leave' ], this.ticks)

    calcSeries()
      .then((res) => {
        this.series = res

        this.options = {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            },
            animations: {
              enabled: false
            },
            defaultLocale: this.$i18n.locale,
            locales: ApexChartLocales
          },
          dataLabels: {
            enabled: false
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                total: {
                  enabled: false,
                }
              }
            },
          },
          xaxis: {
            type: 'datetime',
            categories: this.ticks.timeTicks,
          },
          yaxis: {
            labels: {
              formatter(val: number, opts?: any): string | string[] {
                return val.toFixed()
              }
            }
          },
        }

        this.chartLoading = false
      })
  }

  mounted() {
    this.calcChartSeries()
  }

}
