import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'

import Vue from 'vue'
import { cloneDeep } from "lodash";
import { Component } from "vue-property-decorator";

@Component
export default class ChartExportTableMixin extends Vue {
	sample = { [this.$t('statistics_table_field_title').toString()]: '' }

	getTable(series: ApexChartSeries, labels: Array<string>) {
		if (series && labels) {
			const objArr: Array<Record<string, string | number>> = []

			series.forEach((series) => {
				let obj = cloneDeep(this.sample)
				obj[this.$t('statistics_table_field_title').toString()] = series.name

				series.data.forEach((item, index) => {
					const label = labels[index]

					obj[label] = item?.toString() ?? ''
				})

				objArr.push(obj)
			})

			return objArr
		}

		return null
	}
}
