




































































































import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import { InputSetups } from '@/mixins/input-setups'
import { getInviteLinksStat } from '@/includes/logic/InviteLinks/InviteLinksHelper'
import { InviteLinksWithStat } from '@/includes/logic/InviteLinks/types'
import AccumulatorChart from '@/components/InviteLinks/charts/AccumulatorChart.vue'
import EnterChart from '@/components/InviteLinks/charts/EnterChart.vue'
import LeaveChart from '@/components/InviteLinks/charts/LeaveChart.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Mixins } from 'vue-property-decorator'
import Component from 'vue-class-component'
import moment from 'moment'

@Component({
  methods: {
    moment
  },
  components: {
    LeaveChart,
    EnterChart,
    AccumulatorChart,
    PageTitle,
    MobileLandscapeTriggerLayout,
    EmptyData
  }
})
export default class LinkStatistics extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {

  date = {
    from: moment().add(-30, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  }

  dataLoading = false

  selectedLinks: Array<number> = []

  inviteLinks: InviteLinksWithStat | null = null

  getStatistics() {
    this.dataLoading = true

    this.getLinksStat()
      .then((res) => {
        this.inviteLinks = res
      })
      .finally(() => this.dataLoading = false)
  }

  getLinksStat() {
    return getInviteLinksStat(
      this.$store.getters.activeBoard.board,
      this.$store.state.channelsState.activeChannel.id,
      {
        interval: {
          interval_type: 'Full',
          from: this.date.from,
          to: this.date.to
        },
      })
  }

  get linksOptions(): Array<SelectOption> {
    if (this.inviteLinks) {
      return this.inviteLinks.map(link => {
        return {
          value: link.data.id,
          label: link.data.title
        }
      })
    }

    return []
  }

  get linksToProcess() {
    if (this.inviteLinks) {
      return this.inviteLinks.filter(l => this.selectedLinks.includes(l.data.id))
    }

    return null
  }

  mounted() {
    this.$baseTemplate.saveButton.hide();
    this.dataLoading = true

    this.getLinksStat()
      .then((res) => {
        const key = this.$route.query.key?.toString();

        if (key) {
          const link = res.find(l => l.data.id === Number.parseInt(key))

          if (link) {
            this.selectedLinks.push(link.data.id)
          }
        }

        if (res.length === 1) {
          const firstLink = res[0]

          this.selectedLinks.push(firstLink.data.id)
        }

        this.inviteLinks = res
      })
      .finally(() => this.dataLoading = false)
  }
}
